import React from "react"
import companies from "../Warranty/data"

class KadenProductRanges extends React.Component {
  render() {
    return (
      <React.Fragment>
        <option value="selected">Select Product</option>
        <optgroup label="KS Series Split System Air Conditioner">
          <option value="KS09">KS09 - 2.6kW Split System Air Conditioner</option>
          <option value="KS12">KS12 - 3.5kW Split System Air Conditioner</option>
          <option value="KS18">KS18 - 5.0kW Split System Air Conditioner</option>
          <option value="KS24">KS24 - 7.0kW Split System Air Conditioner</option>
          <option value="KS28">KS28 - 8.0kW Split System Air Conditioner</option>
        </optgroup>
        <optgroup label="KSI Series Split System Air Conditioner">
          <option value="KSI06">KSI06 - 2.0kW Split System Air Conditioner</option>
          <option value="KSI09">KSI09 - 2.6kW Split System Air Conditioner</option>
          <option value="KSI12">KSI12 - 3.5kW Split System Air Conditioner</option>
          <option value="KSI18">KSI18 - 5.0kW Split System Air Conditioner</option>
          <option value="KSI24">KSI24 - 7.0kW Split System Air Conditioner</option>
          <option value="KSI28">KSI28 - 8.0kW Split System Air Conditioner</option>
        </optgroup>
        <optgroup label="KM Series  Multi Air conditioners">
          <option value="KM24">KM24 - 7.3kW KM Multi Outdoor Air Conditioner</option>
          <option value="KM28">KM28 - 8.5kW KM Multi Outdoor Air Conditioner</option>
          <option value="KM46">KM46 - 12.0kW KM Multi Outdoor Air Conditioner</option>
          <option value="KMD09">KMD09 - 2.5kW KMD Multi Ducted Air Conditioner</option>
          <option value="KMD12">KMD12 - 3.5kW KMD Multi Ducted Air Conditioner</option>
          <option value="KMD18">KMD18 - 5.2kW KMD Multi Ducted Air Conditioner</option>
          <option value="KMD24">KMD24 - 7.0kW KMD Multi Ducted Air Conditioner</option>
          <option value="KMC09">KMC09 - 2.5kW KMC Multi Cassette Air Conditioner</option>
          <option value="KMC12">KMC12 - 3.5kW KMC Multi Cassette Air Conditioner</option>
          <option value="KMC18">KMC18 - 5.2kW KMC Multi Cassette Air Conditioner</option>
        </optgroup>
        <optgroup label="KD Series Ducted Air Conditioner">
          <option value="KD24">KD24 - 7.3kW Ducted Air Conditioner</option>
          <option value="KD36">KD36 - 10.5kW Ducted Air Conditioner</option>
          <option value="KD42">KD42 - 12.5kW Ducted Air Conditioner</option>
          <option value="KD48">KD48 - 14.0kW Ducted Air Conditioner</option>
          <option value="KD60">KD60 - 17.0kW Ducted Air Conditioner</option>
        </optgroup>
        <optgroup label="KDI Series Ducted Air Conditioner">
          <option value="KDI24">KDI24 - 7.3kW Ducted Air Conditioner</option>
          <option value="KDI36">KDI36 - 10.5kW Ducted Air Conditioner</option>
          <option value="KDI42">KDI42 - 12.5kW Ducted Air Conditioner</option>
          <option value="KDI48">KDI48 - 14.0kW Ducted Air Conditioner</option>
          <option value="KDI60">KDI60 - 17.0kW Ducted Air Conditioner</option>
        </optgroup>
        <optgroup label="KL Series Evaporative Cooler">
          <option value="KL16">KL16 - Low Profile Evaporative Cooler</option>
          <option value="KL22">KL22 - Low Profile Evaporative Cooler</option>
          <option value="KL25">KL25 - Low Profile Evaporative Cooler</option>
          <option value="KL28">KL28 - Low Profile Evaporative Cooler</option>
        </optgroup>
        <optgroup label="KC Series Evaporative Cooler">
          <option value="KC17">KC17 - Classic Evaporative Cooler</option>
          <option value="KC21">KC21 - Classic Evaporative Cooler</option>
          <option value="KC27">KC27 - Classic Evaporative Cooler</option>
          <option value="KC32">KC32 - Classic Evaporative Cooler</option>
        </optgroup>
        <optgroup label="KUN Series Gas Ducted Heater">
          <option value="KUN315">KUN315 - 3 Star Universal Gas Ducted Heater</option>
          <option value="KUN320">KUN320 - 3 Star Universal Gas Ducted Heater</option>
          <option value="KUN325">KUN325 - 3 Star Universal Gas Ducted Heater</option>
          <option value="KUN330">KUN330 - 3 Star Universal Gas Ducted Heater{" "}</option>
          <option value="KUN415">KUN415 - 4 Star Universal Gas Ducted Heater</option>
          <option value="KUN420">KUN420 - 4 Star Universal Gas Ducted Heater</option>
          <option value="KUN425">KUN425 - 4 Star Universal Gas Ducted Heater</option>
          <option value="KUN430">KUN430 - 4 Star Universal Gas Ducted Heater</option>
          <option value="KUN521">KUN521 - 5 Star Universal Gas Ducted Heater</option>
          <option value="KUN530">KUN530 - 5 Star Universal Gas Ducted Heater</option>
        </optgroup>
        <optgroup label="KEX Series Gas Ducted Heater">
          <option value="KEX315">KEX315 - 3 Star External Gas Ducted Heater</option>
          <option value="KEX320">KEX320 - 3 Star External Gas Ducted Heater</option>
          <option value="KEX328">KEX328 - 3 Star External Gas Ducted Heater</option>
          <option value="KEX415">KEX415 - 4 Star External Gas Ducted Heater</option>
          <option value="KEX420">KEX420 - 4 Star External Gas Ducted Heater</option>
          <option value="KEX428">KEX428 - 4 Star External Gas Ducted Heater</option>
        </optgroup>
        <optgroup label="KCS Series Cassette Air Conditioner">
          <option value="KCS24">KCS24 - 7.0kW Cassette Air Conditioner</option>
          <option value="KCS36">KCS36 - 10.5kW Cassette Air Conditioner</option>
          <option value="KCS42">KCS42 - 12.5kW Cassette Air Conditioner</option>
          <option value="KCS48">KCS48 - 14.0kW Cassette Air Conditioner</option>
        </optgroup>
        <optgroup label="KW Window Air Conditioner">
          <option value="KW09">KW09 - 2.7kW Window Air Conditioner</option>
          <option value="KW12">KW12 - 3.9kW Window Air Conditioner</option>
          <option value="KW18">KW18 - 5.3kW Window Air Conditioner</option>
          <option value="KW21">KW21 - 6.0kW Window Air Conditioner</option>
        </optgroup>
      </React.Fragment>
    )
  }
}

class KadenPartnerDetails extends React.Component {
  render() {
    return (
      <React.Fragment>
        {companies.map((company) => (
          <option value={company} key={company} />
        ))}
      </React.Fragment>
    )
  }
}

class DayOptions extends React.Component {
  render() {
    return (
      <React.Fragment>
        <option value="">D</option>
        <option value="01">1</option>
        <option value="02">2</option>
        <option value="03">3</option>
        <option value="04">4</option>
        <option value="05">5</option>
        <option value="06">6</option>
        <option value="07">7</option>
        <option value="08">8</option>
        <option value="09">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
        <option value="21">21</option>
        <option value="22">22</option>
        <option value="23">23</option>
        <option value="24">24</option>
        <option value="25">25</option>
        <option value="26">26</option>
        <option value="27">27</option>
        <option value="28">28</option>
        <option value="29">29</option>
        <option value="30">30</option>
        <option value="31">31</option>
      </React.Fragment>
    )
  }
}

class MonthOptions extends React.Component {
  render() {
    return (
      <React.Fragment>
        <option value="">M</option>
        <option value="01">January</option>
        <option value="02">Febuary</option>
        <option value="03">March</option>
        <option value="04">April</option>
        <option value="05">May</option>
        <option value="06">June</option>
        <option value="07">July</option>
        <option value="08">August</option>
        <option value="09">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </React.Fragment>
    )
  }
}

class YearOptions extends React.Component {
  render() {
    const year = 2020
    const years = Array.from(new Array(11), (val, index) => index + year)

    return (
      <React.Fragment>
        <option value="">Y</option>
        {years.map((year, index) => {
          return (
            <option key={`year${index}`} value={year}>
              {year}
            </option>
          )
        })}
      </React.Fragment>
    )
  }
}

class StateOptions extends React.Component {
  render() {
    return (
      <React.Fragment>
        <option value="">Select State</option>
        <option value="ACT">ACT</option>
        <option value="NSW">NSW</option>
        <option value="NT">NT</option>
        <option value="QLD">QLD</option>
        <option value="SA">SA</option>
        <option value="TAS">TAS</option>
        <option value="VIC">VIC</option>
        <option value="WA">WA</option>
      </React.Fragment>
    )
  }
}

class InstallationOptions extends React.Component {
  render() {
    return (
      <React.Fragment>
        <option value="">Select Installation Type</option>
        <option value="replace-old">Replace an old unit</option>
        <option value="new-installation">New installation</option>
        <option value="servicing">Servicing a unit</option>
      </React.Fragment>
    )
  }
}

class ContactOptions extends React.Component {
  render() {
    return (
      <React.Fragment>
        <option value="">Select Enquiry Type</option>
        <option value="Product Support">Product Support</option>
        <option value="Warranty Information">Warranty Information</option>
        <option value="General Enquiry">General Enquiry</option>
      </React.Fragment>
    )
  }
}

class ProductOptions extends React.Component {
  render() {
    return (
      <React.Fragment>
        <option value="">Select Product Type</option>
        <option value="Split System Air Conditioner">
          Split System Air Conditioner
        </option>
        <option value="Multi Air Conditioner">Multi Air Conditioner</option>
        <option value="Ducted Air Conditioner">Ducted Air Conditioner</option>
        <option value="Cassette Air Conditioner">
          Cassette Air Conditioner
        </option>
        <option value="Evaporative Cooler">Evaporative Cooler</option>
        <option value="Gas Ducted Heater">Gas Ducted Heater</option>
        <option value="Window Air Conditioner">Window Air Conditioner</option>
      </React.Fragment>
    )
  }
}

export {
  KadenProductRanges,
  KadenPartnerDetails,
  DayOptions,
  MonthOptions,
  YearOptions,
  StateOptions,
  InstallationOptions,
  ContactOptions,
  ProductOptions,
}
