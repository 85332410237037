import React from "react"
import { graphql, Link } from "gatsby"

// SEO
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import SplitCard from "../components/pages/SplitCard"

// Data
import content from "../pages/static-pages/your-perfect-climate.yaml"
import YourClimateForm from "../components/YourClimateForm"

class Index extends React.Component {
  state = { sliderOptions: "" }

  componentDidMount = () => {
    if (typeof window !== "undefined") {
      var win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName("body")[0],
        x = win.innerWidth || docElem.clientWidth || body.clientWidth

      if (x >= 640) {
        this.setState({ sliderOptions: "draggable: false; sets: true;" })
      } else {
        this.setState({ sliderOptions: "center: true;" })
      }
    }
  }

  render() {
    const options = []
    const products = this.props.data.allProductsYaml.edges
    products.forEach((product) => {
      options.push(
        <option value={product.node.id} key={product.node.id}>
          {product.node.title}
        </option>
      )
    })

    const breadcrumbs = [
      {
        label: content.title,
        path: "/your-perfect-climate/",
        active: true,
      },
    ]

    return (
      <Layout>
        <SEO
          title={content.title}
          description={content.seo_description}
          image={content.seo_image}
        />

        <Breadcrumbs items={breadcrumbs} absolute={true} contrast={true} />

        {/* Feature Banner */}

        <div
          className="feature-banner your-perfect-climate bg-color uk-flex uk-flex-center uk-background-cover uk-light uk-preserve-color"
          data-src={content.feature.image.url}
          uk-img=""
        >
          <div className="uk-width-1-1 uk-flex uk-flex-center">
            <div className="uk-text-center uk-padding uk-margin-auto-vertical">
              <h5
                uk-icon={
                  "icon: " +
                  content.feature.icon.type +
                  "; ratio: " +
                  content.feature.icon.ratio +
                  ";"
                }
              ></h5>
              <h1 className="uk-margin-small uk-text-bold uk-width-3-5 uk-margin-auto">
                {content.feature.title}
              </h1>
              <h5 className="uk-margin-remove-top">
                {content.feature.tagline}
              </h5>
              <a
                href="#installer-form"
                className="uk-button uk-button-large uk-button-primary uk-border-pill uk-margin-bottom"
              >
                {content.feature.link.btnText}
              </a>

              <p className="uk-margin-remove-top header-link">
                {content.feature.explainer.text}{" "}
                <Link to={content.feature.explainer.url}>
                  {content.feature.explainer.link_text}
                </Link>
              </p>
            </div>
          </div>
        </div>

        {/* Feature Video */}
        <div className="uk-section uk-section-small uk-padding-remove-bottom">
          <div className="uk-container">
            <SplitCard
              title={content.reasonsCard.title}
              video={content.reasonsCard.video}
              margin={true}
              align="left"
            />
          </div>
        </div>

        <div className="uk-section uk-section-small uk-padding-remove-top">
          <div className="uk-container">
            <div className="uk-text-center">
              <a
                href="#installer-form"
                className="uk-button uk-button-large uk-button-primary uk-border-pill uk-margin-bottom"
              >
                Request an installer
              </a>
            </div>
          </div>
        </div>

        {/* Why Choose Kaden Reasons */}
        <div className="uk-section uk-section-secondary uk-dark bg-logo-detail">
          <div className="uk-container uk-container-expand">
            <div className="uk-width-1-1 uk-width-2-3@l uk-margin-auto uk-text-center">
              <h3 className="uk-margin-bottom uk-margin-top uk-text-bold">
                {content.reasonsTitle}
              </h3>
              <div
                className="uk-position-relative uk-visible-toggle uk-margin-medium-top"
                tabIndex={-1}
                uk-slider={this.state.sliderOptions}
              >
                <div className="uk-slider-items icon-slider">
                  {content.reasons.map((reason) => (
                    <div
                      className="uk-width-4-5 uk-width-1-2@s uk-width-1-4@m"
                      key={reason.title}
                    >
                      <span
                        className="uk-flex uk-flex-center uk-margin-small uk-margin-bottom uk-text-primary"
                        uk-icon={
                          "icon:" +
                          reason.icon.type +
                          "; ratio: " +
                          reason.icon.ratio +
                          ";"
                        }
                      ></span>
                      <p className="uk-text-primary uk-text uk-width-4-5 uk-margin-auto">
                        {reason.title}
                      </p>
                    </div>
                  ))}
                </div>
                <ul className="uk-slider-nav uk-dotnav uk-margin uk-flex-center uk-hidden@m uk-width-1-1"></ul>
              </div>
            </div>
          </div>
        </div>

        {/* Systems for every home */}
        <div className="uk-section">
          <div className="uk-container">
            <div className="uk-section uk-section-small uk-margin-small uk-margin-small-top uk-text-left">
              <h2 className="uk-text-center ">{content.panelsTitle}</h2>
              <div
                className="uk-container uk-margin-large-top"
                tabIndex="-1"
                uk-slider={this.state.sliderOptions}
              >
                <div className="uk-slider-items image-slider">
                  {content.panels.map((item) => (
                    <a
                      href={item.link.url}
                      key={item.link.url}
                      className="uk-padding-small uk-width-1-3 uk-width-1-2@s uk-width-1-3@m"
                    >
                      <img
                        className="uk-flex uk-flex-center uk-margin-small uk-margin-bottom uk-text-primary"
                        src={item.image.url}
                        alt={item.title}
                      />
                      <h5 className="uk-text-primary uk-width-1-1 uk-width-1-1@m uk-margin-small-top">
                        {item.title}
                      </h5>
                    </a>
                  ))}
                </div>
                <ul className="uk-slider-nav uk-dotnav uk-margin uk-flex-center uk-width-1-1"></ul>
              </div>
            </div>
          </div>
        </div>

        {/* Kaden story */}
        <div className="uk-section uk-section-small uk-padding-remove-bottom">
          <div className="uk-container">
            <SplitCard
              title={content.storyCard.title}
              video={content.storyCard.video}
              desc={content.storyCard.desc}
              margin={true}
              align="right"
            />
          </div>
        </div>

        {/* Steps */}
        <div className="uk-section uk-section-default uk-padding-remove-bottom">
          <div className="uk-container">
            <div className="uk-width-2-3@l uk-margin-auto uk-margin-large-bottom uk-text-center">
              <h2 className="uk-text-bold">{content.stepsContent.title}</h2>
              <div
                className="uk-position-relative uk-visible-toggle uk-margin-medium-top"
                tabIndex={-1}
                uk-slider={this.state.sliderOptions}
              >
                <div className="uk-slider-items uk-flex-center">
                  {content.stepsContent.steps.map((step, i) => (
                    <div
                      className="uk-width-1-3 uk-width-1-2@s uk-width-1-4@m"
                      key={i}
                    >
                      <span
                        className="uk-text-primary"
                        uk-icon={
                          "icon: " +
                          step.icon.type +
                          "; ratio: " +
                          step.icon.ratio +
                          ";"
                        }
                      ></span>
                      <p className="uk-text-primary uk-text-small uk-width-4-5 uk-width-3-5@m uk-width-4-5@l uk-margin-auto">
                        {step.title}
                      </p>
                    </div>
                  ))}
                </div>
                <ul className="uk-slider-nav uk-dotnav uk-margin uk-flex-center uk-hidden@m uk-width-1-1"></ul>
              </div>
            </div>
          </div>
        </div>

        {/* Form */}
        <div className="uk-section uk-section-default uk-padding-remove-top">
          <div className="uk-container">
            <div className="uk-grid">
              <div>
                <YourClimateForm />
              </div>
            </div>
            {/* Form */}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query SearchQuery {
    allProductsYaml(limit: 1000) {
      edges {
        node {
          id
          title
          fields {
            slug
          }
        }
      }
    }
  }
`
