import React from "react"
import { Formik, Form, useField } from "formik"
import * as Yup from "yup"
import moment from "moment"
import "moment-timezone"

import { StateOptions, InstallationOptions } from "./Dropdowns"
import { navigate } from "gatsby"

class YourClimateForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { submissionMessage: "" }
  }

  render() {
    const TextInput = ({ label, required, ...props }) => {
      const [field, meta] = useField(props)
      return (
        <div className="uk-margin">
          <label
            className="uk-text-bold uk-display-block uk-margin-small"
            htmlFor={props.id || props.name}
          >
            {label} {required && <abbr title="required">*</abbr>}
          </label>
          <input className="uk-input" {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="uk-alert uk-alert-danger uk-margin-remove-top">
              {meta.error}
            </div>
          ) : null}
        </div>
      )
    }

    const TextArea = ({ label, required, ...props }) => {
      const [field, meta] = useField(props)
      return (
        <div className="uk-margin">
          <label
            className="uk-text-bold uk-display-block uk-margin-small"
            htmlFor={props.id || props.name}
          >
            {label} {required && <abbr title="required">*</abbr>}
          </label>
          <textarea className="uk-textarea" {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="uk-alert uk-alert-danger uk-margin-remove-top">
              {meta.error}
            </div>
          ) : null}
        </div>
      )
    }

    const Select = ({ label, required, ...props }) => {
      const [field, meta] = useField(props)
      return (
        <div className="uk-margin">
          <label
            className="uk-text-bold uk-display-block uk-margin-small"
            htmlFor={props.id || props.name}
          >
            {label} {required && <abbr title="required">*</abbr>}
          </label>
          <select className="uk-select" {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="uk-alert uk-alert-danger uk-margin-remove-top">
              {meta.error}
            </div>
          ) : null}
        </div>
      )
    }

    const CheckboxInputBasic = ({ label, required, ...props }) => {
      const [field, meta] = useField(props)
      return (
        <React.Fragment>
          <input {...field} {...props} />
          <label className="uk-border-rounded" htmlFor={props.id}>
            {label}
          </label>
          {meta.touched && meta.error ? (
            <div className="uk-alert uk-alert-danger uk-margin-remove-top">
              {meta.error}
            </div>
          ) : null}
        </React.Fragment>
      )
    }

    const CheckboxInput = ({ label, required, ...props }) => {
      const [field, meta] = useField(props)
      return (
        <React.Fragment>
          <label>
            <input {...field} {...props} />{" "}
            <span dangerouslySetInnerHTML={{ __html: label }} />{" "}
            {required && <abbr title="required">*</abbr>}
          </label>
          {meta.touched && meta.error ? (
            <div className="uk-alert uk-alert-danger uk-margin-remove-top">
              {meta.error}
            </div>
          ) : null}
        </React.Fragment>
      )
    }
    const formattedLocalDateTime = moment()
      .tz("Australia/Melbourne")
      .format("DD/MM/YYYY HH:mm:ss")

    const productType = [
      {
        label: "Split System Air Conditioners",
        id: "split-ac",
        value: "split-ac",
      },
      {
        label: "Multi Air Conditioners",
        id: "multi-ac",
        value: "multi-ac",
      },
      {
        label: "Ducted Air Conditioners",
        id: "ducted-ac",
        value: "ducted-ac",
      },
      {
        label: "Gas Ducted Heater",
        id: "gas-ducted",
        value: "gas-ducted",
      },
      {
        label: "Evaporative Cooler",
        id: "evap-cooler",
        value: "evap-cooler",
      },
      {
        label: "Cassette Air Conditioner",
        id: "cassette",
        value: "cassette",
      },
      {
        label: "Window Air Conditioner",
        id: "window-ac",
        value: "window-ac",
      },
      {
        label: "All Products",
        id: "all-products",
        value: "all-products",
      },
    ]

    const encode = (data) => {
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&")
    }

    const pathNameWithoutSlash = window.location.pathname.replaceAll("/", "")

    const form = (
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          street: "",
          suburb: "",
          postcode: "",
          state: "",
          productType: [],
          installationType: "",
          message: "",
          dateAdded: `${formattedLocalDateTime}`,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required("Required"),
          lastName: Yup.string().required("Required"),
          phone: Yup.string()
            .required("Required")
            .matches(
              /^[0-9]{10}|[0-9]{9}$/,
              "Must be exactly 10 digits e.g. 0432112112"
            ),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          suburb: Yup.string().required("Required"),
          postcode: Yup.number()
            .typeError("This field must be a number only")
            .required("Required"),
          state: Yup.string().required("Required"),
          productType: Yup.array()
            .min(1, "Please select at least one product.")
            .required("Required"),
          message: Yup.string(),
        })}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)
          this.setState({ submissionMessage: null })
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
              "form-name": "request-form",
              ...values,
            }),
          })
            .then((response) => {
              if (response.ok) {
                navigate(`/thank-you?source=${pathNameWithoutSlash}`)
              } else {
                this.setState({
                  submissionMessage:
                    "There was an error with your submission. Please refresh and try again.",
                })
              }
            })
            .catch((error) => {
              this.setState({
                submissionMessage:
                  "There was an error with the form submission. Please refresh this page and try again.",
              })
            })
            .finally(() => actions.setSubmitting(false))
        }}
      >
        {({ isSubmitting, setFieldValue, errors, touched, status }) => (
          <div
            className={
              isSubmitting ? "form-container spinner" : "form-container"
            }
          >
            <Form
              className="uk-grid-small uk-grid-row-medium uk-grid-column-medium uk-grid warranty-form"
              id="installer-form"
              name="request-form"
              data-netlify="true"
              netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="request-form" />
              <div className="uk-width-1-1@s uk-grid-margin">
                <h2>Your personal details</h2>
              </div>
              <div className="uk-width-1-2@s uk-grid-margin uk-first-column">
                <TextInput
                  label="First Name"
                  name="firstName"
                  type="text"
                  placeholder="First name"
                  required="true"
                />
              </div>
              <div className="uk-width-1-2@s uk-grid-margin">
                <TextInput
                  label="Last Name"
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  required="true"
                />
              </div>
              <div className="uk-width-1-1@s uk-width-1-2@m uk-grid-margin">
                <TextInput
                  label="Contact Number"
                  name="phone"
                  type="text"
                  placeholder="0412345678 or 039901234"
                  maxLength="10"
                  required="true"
                />
              </div>
              <div className="uk-width-1-1@s uk-width-1-2@m uk-grid-margin">
                <TextInput
                  label="Email Address"
                  name="email"
                  type="email"
                  placeholder="jane@example.com"
                  required="true"
                />
              </div>
              <div className="uk-width-1-3@s uk-grid-margin">
                <TextInput
                  label="Suburb"
                  name="suburb"
                  id="suburb"
                  type="text"
                  placeholder="Melbourne"
                  required="true"
                />
              </div>
              <div className="uk-width-1-3@s uk-width-1-2 uk-grid-margin">
                <TextInput
                  label="Postcode"
                  name="postcode"
                  type="text"
                  placeholder="3000"
                  maxLength="4"
                  required="true"
                />
              </div>
              <div className="uk-width-1-3@s uk-width-1-2 uk-grid-margin">
                <Select label="State" name="state" id="state" required="true">
                  <StateOptions />
                </Select>
              </div>
              <div className="uk-width-1-1@s uk-grid-margin">
                <hr className="uk-divider-icon" />
              </div>
              <div className="uk-width-1-1@s uk-grid-margin">
                <h2>System Type</h2>
              </div>
              <div className="uk-width-1-1@s uk-grid-margin">
                <fieldset>
                  <legend>
                    Please select the products below that relate to your quote
                    request <abbr title="required">*</abbr>
                  </legend>
                  <div
                    className="uk-grid uk-margin uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m checkbox-tiled uk-text-center"
                    uk-height-match="target: > div > label"
                  >
                    {productType.map((product, index) => (
                      <div key={index}>
                        <CheckboxInputBasic
                          label={product.label}
                          type="checkbox"
                          name="productType"
                          id={product.id}
                          value={product.value}
                          className="uk-checkbox"
                        />
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="uk-width-1-1@s uk-grid-margin">
                <hr className="uk-divider-icon" />
              </div>
              <div className="uk-width-1-1@s uk-grid-margin">
                <h2>Request type</h2>
              </div>
              <div className="uk-width-1-2@s uk-grid-margin">
                <Select
                  label="Which of the following best describes your installation"
                  name="installationType"
                  id="installationType"
                >
                  <InstallationOptions />
                </Select>
              </div>
              <div className="uk-width-1-1@s uk-grid-margin">
                <TextArea
                  label="Message"
                  name="message"
                  type="message"
                  placeholder="Enter an optional message"
                  rows="5"
                />
              </div>

              <div className="uk-width-1-1@s uk-grid-margin" hidden>
                <label>
                  Don't fill this out if you're human:{" "}
                  <input name="bot-field" />
                </label>
              </div>

              <div className="uk-hidden uk-width-1-1@s uk-grid-margin">
                <TextInput
                  label="Date submitted (Not required to complete - automated)"
                  name="DateAdded"
                  id="DateAdded"
                  type="hidden"
                  className="uk-input"
                  required={false}
                  value={formattedLocalDateTime}
                />
              </div>
              <div className="uk-width-1-1@s uk-grid-margin">
                <button
                  type="submit"
                  className="uk-button uk-button-primary uk-border-pill"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
                <span className="uk-margin-left custom-error">
                  {Object.keys(errors).length ? (
                    <div className="uk-alert-danger uk-margin-top">
                      <span
                        uk-icon="warning"
                        className="uk-padding-small"
                      ></span>{" "}
                      Please fill out the required fields
                    </div>
                  ) : null}
                  {this.state.submissionMessage}
                </span>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    )
    return form
  }
}

export default YourClimateForm
